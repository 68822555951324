@font-face {
    font-family: iMotoFont;
    src: url(../../../assets/fonts/BlackOps.ttf);
}

@font-face {
    font-family: BlackBold;
    src: url(../../../assets/fonts/Archivo-Black.ttf);
}

.appDevContainer {
    /* border: 0.1em solid #BABABA; */
    margin: 1em;
    background-image: url(../../../assets/welp.png);
    background-size: cover;
    height: 60vh;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1em;
}

.appDevIcons {
    fill: #FFF;
    height: 2em;
    width: 2em;
    margin: 0.5em;
}

@media (orientation: portrait) {
    .appDevContainer {
        margin-bottom: 4em;
    }
}

@media (orientation: landscape) {
    .appDevContainer {
        background-size: 100% 100%;
    }
}