@font-face {
    font-family: iMotoFont;
    src: url(../../../../assets/fonts/BlackOps.ttf);
}

@font-face {
    font-family: BlackBold;
    src: url(../../../../assets/fonts/Archivo-Black.ttf);
}

.iMotoContainer {
    border: 0.1em solid #BABABA;
    border-radius: 0.5em;
    margin: 1em;
}

.iMotoLogo {
    padding: 0.4em;
    text-align: end;
    font-family: iMotoFont;
    font-size: 2em;
    color: #000;
}

.imotosplash {
    width: 100%;
}

.imotodesc {
    text-align: start;
    padding: 1em;
    line-height: 1.3em;
    font-size: 1.2em;
}

.imotoLearnDiv {
    display: flex;
    justify-content: flex-end;
}

.imotoButtonDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imotoButton {
    align-self: flex-end;
    background-color: #FFF;
    /* background-color: #FF7171; */
    /* background-color: #D5D69C; */
    padding: 0.25em;
    margin-right: 1em;
    margin-bottom: 1em;
    border-radius: 0.35em;
    /* border: 0.1em solid #9B9B9B; */
    border: 0.1em solid #BFBFBF;
    /* border: 0px; */
    display: inline-flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    cursor: pointer;
}

.learnDiv {
    margin-right: 1em;
    font-size: 1em;
    /* color: #FFF; */
    /* color: #D5D69C; */
    font-family: BlackBold;
    color: #000;
}

.learnPNGDiv {
    align-content: center;
}

.learnPNG {
    height: 2em;
    width: 2em;
    margin-top: 0.1em;
}

.rightCircle {
    /* fill: #D5D69C; */
    /* fill: #FFF; */
    fill: #D5D69C;
    width: 2em;
    height: 2em;
    margin-top: 0.2em;
}

/* .imotoRightDiv {
    display: none;
} */

/* .storeIcons {
    height: 3em;
    width: 3em;
    margin-bottom: 3em;
    fill: #BFBFBF;
} */

/* @media (orientation: landscape) {
    .imageTextDiv {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    .imotodesc {
        width: 60%;
        text-align: center;
        margin: auto;
    }
    .imotosplash {
        border-radius: 0.5em;
        width: 70%;
    }
    .imotoRightDiv {
        display: flex;
        width: 30%;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
    .imotoFlexDiv {
        display: flex;
    }
} */

@media (orientation: landscape) {
    .imotodesc {
        padding: 1em;
        line-height: 1.8em;
        font-size: 1.1em;
    }
    .imotoLearnDiv {
        justify-content: flex-start;
    }
    .imotoButton {
        margin-left: 1.5em;
    }
}

@media (orientation: portrait) {
    .iMotoLogo {
        text-align: start;
    }
}