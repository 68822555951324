@font-face {
    font-family: BlackBold;
    src: url(../../../assets/fonts/Archivo-Black.ttf);
}

.attention {
    font-family: BlackBold;
    color: #000;
    font-size: 2.5em;
    text-align: start;
    margin-bottom: 0.25em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.message {
    text-align: start;
    font-size: 1.25em;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em;
}

.idea {
    text-align: start;
    font-size: 1.25em;
    margin-bottom: 2em;
    margin-left: 1em;
    margin-right: 1em;
}

.attentions {
    font-family: BlackBold;
    color: #000;
    font-size: 2.5em;
    text-align: start;
    margin-bottom: 0.25em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-top: 1.25em;
}


.featured {
    color: #A8A8A8;
    font-size: 1.1em;
    letter-spacing: 0.7em;
}

.questions {
    color: #A8A8A8;
    font-size: 1.1em;
    letter-spacing: 0.7em;
    padding-left: 0.5em;
    margin-top: 2.5em;
}

.contactButtonHome {
    /* background-color: #D5D695; */
    background-color: #FF7171;
    color: #FFF;
    padding: 0.5em;
    font-family: BlackBold;
    font-size: 1.5em;
    border: 0px;
    border-radius: 0.25em;
    margin-top: 0.25em;
    margin-bottom: 2.5em;
    cursor: pointer;
}

.phoneIcon {
    color: #FFF;
    fill: #FFF;
    stroke: #FFF;
    height: 0.75em;
    width: 0.75em;
    margin-left: 0.75em;
}

@media (orientation: landscape) {
    .homeFlex {
        display: flex;
        align-items: center;
        width: 100%;
    }
    
    .homeLeft {
        width: 70%;
    }
    
    .homeRight {
        width: 30%;
    }
    .featured {
        font-size: 1em;
    }
}