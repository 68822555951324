@font-face {
    font-family: BlackBold;
    src: url(../../../assets/fonts/Archivo-Black.ttf);
}

.servicesHeader {
    font-family: BlackBold;
    color: #000;
    margin-left: 0.5em;
    font-size: 2em;
    text-align: start;
}

.servicesDesc {
    margin: 1em;
    margin-top: 0.25em;
    line-height: 1.7em;
    font-size: 1.2em;
    text-align: start;
}

.servicesIcons {
    /* fill: #FF7171; */
    fill: #8059C9;
    height: 2em;
    width: 2em;
    padding: 0.5em;
}

.reactNativeContainer {
    /* border: 0.1em solid #6E6E6E; */
    border: 0.1em solid #BABABA;
    border-radius: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.techName {
    letter-spacing: 0.7em;
    margin: 1em;
}

.techBody {
    margin: 1em;
    line-height: 1.7em;
    text-align: start;
    margin-bottom: 2.5em;
}

.techImage {
    width: 100%;
}

@media (orientation: landscape) {
    .reactNativeContainer {
        margin: 1em;
    }
    .techContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin-top: 2em;
        margin-bottom: 3em;
    }
    .reactNativeContainer {
        width: 30vw;
    }
    .servicesIcons {
        height: 2.5em;
        width: 2.5em;
    }
    .iconContainer {
        margin-top: 3em;
    }
}