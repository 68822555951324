@font-face {
    font-family: BlackBold;
    src: url(../../../assets/fonts/Archivo-Black.ttf);
}

@font-face {
    font-family: imotoFont;
    src: url(../../../assets/fonts/BlackOps.ttf);
}

@font-face {
    font-family: lobster;
    src: url(../../../assets/fonts/lobster.ttf);
}

.workHeader {
    font-family: BlackBold;
    font-size: 2em;
    text-align: start;
    color: #000;
    margin-left: 0.5em;
}

.workDesc {
    text-align: start;
    margin-left: 1em;
}

.workImotoContainer {
    display: flex;
    align-items: center;
    margin: 1em;
    margin-top: 4em;
    margin-bottom: 4em;
}

.workImotoRight {
    border: 0.1em solid #BFBFBF;
    border-radius: 0.5em;
    line-height: 1.7em;
    padding: 1em;
}

.workImotoHeader {
    font-family: imotoFont;
    color: #000;
    font-size: 2em;
    margin: 0.5em;
}

.iMotoVideo {
    border: 0.1em solid #BFBFBF;
}

.workQuikventoryContainer {
    display: flex;
    align-items: center;
    margin: 1em;
    margin-top: 4em;
    margin-bottom: 4em;
    line-height: 1.7em;
}

.workQuikHeader {
    font-family: lobster;
    color: #000;
    font-size: 2em;
    margin: 0.5em;
}

.workQuikventoryLeft {
    border: 0.1em solid #BFBFBF;
    border-radius: 0.5em;
    padding: 1em;
}

.quikVideo {
    border: 0.1em solid #BFBFBF;
}

.workIcons {
    /* fill: #3473FF; */
    fill: #8059C9;
    margin: 0.5em;
    width: 2em;
    height: 2em;
}

.workParagraph {
    text-align: start;
    margin: 1em;
}

@media (orientation: landscape) {
    .workImotoHeader {
        margin-top: 0em;
    }
    .workQuikHeader {
        margin-top: 0em;
    }
    .workContentContainer {
        width: 100%;
        margin: auto;
    }
    .workImotoContainer {
        border: 0.1em solid #BFBFBF;
        border-radius: 0.5em;
    }
    .workImotoLeft {
        padding: 1em;
    }
    .workImotoRight {
        border: 0px;
    }
    .workQuikventoryContainer {
        border: 0.1em solid #BFBFBF;
        border-radius: 0.5em;
    }
    .workQuikventoryLeft {
        border: 0px;
    }
    .workQuikventoryRight {
        padding: 1em;
    }
    .iMotoVideo {
        height: 60vh;
        margin-right: 1em;
    }
    .quikVideo {
        height: 60vh;
        margin-left: 1em;
    }
    .workParagraph {
        margin-left: 5em;
        margin-right: 5em;
    }
}

@media (orientation: portrait) {
    .workImotoContainer {
        flex-direction: column-reverse;
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .workQuikventoryContainer {
        flex-direction: column;
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .iMotoVideo {
        width: 100%;
        margin-top: 2em;
    }
    .quikVideo {
        width: 100%;
        margin-top: 2em;
    }
}