@font-face {
    font-family: BlackBold;
    src: url(../../../assets/fonts/Archivo-Black.ttf);
}

.contactHeader {
    font-family: BlackBold;
    color: #000;
    margin-left: 0.5em;
    text-align: start;
    font-size: 2em;
}

.contactSubHeader {
    text-align: start;
    margin: 1em;
    margin-top: 0.25em;
}

.contactBodyContainer {
    display: flex;
    justify-content: center;
    padding: 1em;
    margin-bottom: 3em;
    margin-top: 4em;
}

.contactBodyLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.contactBodyRight {
    width: 34%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: yellow; */
}

.contentBodyRightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    border: 0.1em solid #BFBFBF;
    border-radius: 1em;
    margin-bottom: 6em;
}

.contactNumber {
    font-family: BlackBold;
    font-size: 1.25em;
    color: #000;
    margin: 1em;
}

.contactInput {
    display: flex;
    justify-content: center;
    margin: 1em;
    border: 0px;
    border-bottom: 0.1em solid #BFBFBF;
    width: 80%;
}

.contactPageButton {
    background-color: #FF7171;
    color: #FFF;
    padding: 0.5em;
    font-family: BlackBold;
    font-size: 1.25em;
    border: 0px;
    border-radius: 0.25em;
    margin-top: 0.25em;
}

/* .contactFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3em;
    font-size: 1.25em;
}

.contactFormLeft {
    text-align: end;
    margin-right: 1em;
    width: 13em;
    text-align: end;
}

.contactFormRight {
    width: 13em;
    text-align: start;
} */

input {
    padding: 0.85em;
    border: 0px;
    /* border-bottom: 0.1em solid #BFBFBF; */
    text-align: start;
    font-size: 1.25em;
    width: 100%;
}

textarea {
    padding: 0.85em;
    border: 0px;
    /* border-bottom: 0.1em solid #BFBFBF; */
    text-align: start;
    font-size: 1.25em;
    width: 100%;
    height: 7em;
    resize: none;
}

.icons {
    align-self: center;
    height: 1.5em;
    width: 1.5em;
    fill: green;
    margin-right: 1em;
}

.noIcons {
    align-self: center;
    height: 1.5em;
    width: 1.5em;
    fill: red;
    margin-right: 1em;
}

.iconss {
    margin-right: 1em;
    margin-top: 1.2em;
    align-self: flex-start;
    height: 1.5em;
    width: 1.5em;
    fill: green;
}

.noIconss {
    margin-right: 1em;
    margin-top: 1.2em;
    align-self: flex-start;
    height: 1.5em;
    width: 1.5em;
    fill: red;
}

::placeholder {
    color: #BFBFBF;
}

@media (orientation: portrait) {
    .contactBodyLeft {
        width: 100%;
        margin-left: 0em;
    }
    .contactBodyContainer {
        padding: 0em;
    }
    .contactInput {
        margin-left: 0em;
        margin-right: 0em;
        width: 85%;
    }
    input {
        width: 100%;
    }
    textarea {
        width: 100%;
    }
}