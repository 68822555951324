.footerContainer {
    background-color: #000;
    font-size: 1em;
    padding-top: 2em;
}

.footerLeftRightDiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footerLeftDiv {
    margin-top: 3em;
    margin-bottom: 3em;
}

.footerRightDiv {
    color: #FFF;
}

.footerEmail {
    /* fill: #FFF; */
    height: 3em;
    width: 3em;
    color: #FFF;
}

.footerPhone {
    height: 3em;
    width: 3em;
}

.footerList {
    list-style-type: none;
    padding: 1em;
    color: #FFF;
}

.footerCopyright {
    padding: 1em;
    color: #BFBFBF;
}