.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    margin-bottom: 1.5em;
}

.logo {
    text-align: start;
    font-size: 1.5em;
}

.links {
    color: #8a8a8a;
}
.navlinks {
    font-size: 2em;
    color: #8a8a8a;
}

.links:hover {
    color: #000;
}

.menuButton {
    height: 2em;
    width: 3.2em;
}

.footerLi {
    list-style-type: none;
    padding: 1em;
}

.theModal{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #FFF;
    z-index: 9;
}

@media (orientation: landscape) {
    .menuButton {
        display: none;
    }
    .footerLi {
        display: block;
    }
}

@media (orientation: portrait) {
    .menuButton {
        display: block;
    }
    .footerLi {
        display: none;
    }
}